import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoint from "../api/ajax-endpoints";

type currencyType = {
  currentCurrencyValue: string;
  activeProductName: string;
  aggregator_id: string;
};

const getTotalOutstandingLoans = async ({
  currentCurrencyValue,
  activeProductName,
  aggregator_id,
}: currencyType) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoint.TOTAL_OUSTANDING_LOANS}?currency=${currentCurrencyValue}&product=${activeProductName}&aggregator_id=${aggregator_id}`
  );
  return data.data;
};

const useTotalOutstandingLoans = ({
  currentCurrencyValue,
  activeProductName,
  aggregator_id,
}: currencyType) => {
  return useQuery(
    [
      { currentCurrencyValue, activeProductName, aggregator_id },
      "getTotalOutstanding",
    ],
    getTotalOutstandingLoans,
    {
      enabled:
        currentCurrencyValue && activeProductName && aggregator_id
          ? true
          : false,
      retry: 4,
    }
  );
};

export default useTotalOutstandingLoans;
