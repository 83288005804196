import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import {GET_RECENT_DISBURSEMENTS_DATA} from "../api/ajax-endpoints";

interface IgetRecentDisbursement {
  currentCurrencyValue: string;
  activeProductName: string;
  aggregatorId: string;
}
const getRecentDisbursements = async ({
  currentCurrencyValue,
  activeProductName,
  aggregatorId,
}: IgetRecentDisbursement) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_RECENT_DISBURSEMENTS_DATA}?currency=${currentCurrencyValue}&product=${activeProductName}&aggregatorId=${aggregatorId}`
  );
  return data.data;
};

export default function useRecentDisbursements({
  currentCurrencyValue,
  activeProductName,
  aggregatorId,
}: IgetRecentDisbursement) {
  return useQuery(
    [
      { currentCurrencyValue, activeProductName, aggregatorId },
      "getRecentDisbursements",
    ],
    getRecentDisbursements,
    {
      enabled:
        currentCurrencyValue && activeProductName && aggregatorId
          ? true
          : false,

      refetchOnWindowFocus: false,
    }
  );
}
