import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

interface IDashBoardDisbursementGraph {
  aggregatorId: number;
  filter: number;
  currentCurrencyValue: string;
  activeProductName: string;
}
const getDashboardDisbursementsGraph = async ({
  aggregatorId,
  filter,
  currentCurrencyValue,
  activeProductName,
}: IDashBoardDisbursementGraph) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.DASHBOARD_DISBURSEMENT_GRAPH}?aggregatorId=${aggregatorId}&currency=${currentCurrencyValue}&filter=${filter}&product=${activeProductName}`
  );
  return data;
};

export default function useDashboardDisbursementGraph({
  aggregatorId,
  filter,
  currentCurrencyValue,
  activeProductName,
}: IDashBoardDisbursementGraph) {
  return useQuery(
    [
      { aggregatorId, filter, currentCurrencyValue, activeProductName },
      "getDashboardDisbursementsGraph",
    ],
    getDashboardDisbursementsGraph,
    {
      enabled:
        currentCurrencyValue && activeProductName && aggregatorId
          ? true
          : false,

      refetchOnWindowFocus: false,
      cacheTime: 1000,
    }
  );
}
