import { Fragment } from "react";
import DisbursementsGraph from "./Disbursements/DisbursementsGraph.component";
import BorrowersGraph from "./Borrowers/BorrowersGraph.component";
import RepaymentsGraph from "./Repayments/RepaymentsGraph.component";
import ButtonLoader, { Loader } from "../../../../UI/Loaders/Loaders";

import styles from "./DashboardGraphs.module.scss";

export default function LoanDashboardGraphs({
  currentCurrency,
  activeProductName,
  aggregatorDetails,
}: {
  currentCurrency: string;
  activeProductName: string;
  aggregatorDetails: any;
}) {

  return (
    <div className={`${styles.alignGraphs}  advancly-wrapper-container `}>
      {aggregatorDetails ? (
        <Fragment>
          <DisbursementsGraph
            aggregatorId={aggregatorDetails && aggregatorDetails.aggregator_id}
            currentCurrency={currentCurrency}
            activeProductName={activeProductName}
          />
          <RepaymentsGraph
            aggregatorId={aggregatorDetails && aggregatorDetails.aggregator_id}
            currentCurrency={currentCurrency}
            activeProductName={activeProductName}
          />
          <BorrowersGraph
            aggregatorId={aggregatorDetails && aggregatorDetails.aggregator_id}
          />
        </Fragment>
      ) : (
        <div className="d-flex justify-content-center">
          <ButtonLoader />
        </div>
      )}
    </div>
  );
}
