import axios from "axios";
import { BASE_URL2, BANK_STATEMENT_EXTRACTION_BASE_URL, CREDIT_SCORE_BASE_URL } from "../appConstants";
import { appInsights } from "../config/appInsights";
import { getFromLocalStorage } from "../helpers/localStorage";

export const token = () => {
  const token = getFromLocalStorage("sso_auth_token");
  if (!token) {
    return null;
  }
  return token;
};

const axiosInstance = axios.create({
  baseURL: BASE_URL2,
  headers: {
    "content-security-policy":
      "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
  },
});

const axiosFormDataInstance = axios.create({
  baseURL: BASE_URL2,
  headers: {
    "content-security-policy":
      "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
  },
});

const axiosInstance_bankstatement_extraction = axios.create({
  baseURL: BANK_STATEMENT_EXTRACTION_BASE_URL,
  headers: {
    "content-security-policy":
      "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
  },
});

const axiosInstance_credit_scoring = axios.create({
  baseURL: CREDIT_SCORE_BASE_URL,
  headers: {
    "content-security-policy":
      "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Authorization: `Token ${token()}`,
    };
    // you can also do other modification in config
    return config;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location.replace("/logout");
    }

    appInsights.trackException({ exception: error });
    return Promise.reject(error);
  },
);

axiosFormDataInstance.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token()}`,
    };
    // you can also do other modification in config
    return config;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location.replace("/logout");
    }

    appInsights.trackException({ exception: error });
    return Promise.reject(error);
  },
);

axiosInstance_bankstatement_extraction.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token()}`,
    };
    // you can also do other modification in config
    return config;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location.replace("/logout");
    }

    if (error.response && error.response.status === 500) {
      throw new Error("Server error");
    }

    appInsights.trackException({ exception: error });
    return Promise.reject(error);
  },
);

axiosInstance_credit_scoring.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Authorization: `Token ${token()}`,
    };
    // you can also do other modification in config
    return config;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location.replace("/logout");
    }

    appInsights.trackException({ exception: error });
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    const { baseURL, headers, method, url } = error?.config;

    if (error.response && error.response.status === 401) {
      window.location.replace("/logout");
    }

    if (error.response && error.response.status === 500) {
      throw new Error("Server error");
    }

    appInsights.trackException({
      exception: error,
      properties: {
        fileName: "dotNetApiMethods.js",
        baseURL,
        headers,
        method,
        url,
        response: error?.response?.data,
      },
    });
    return Promise.reject(error);
  },
);

axiosInstance_bankstatement_extraction.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    const { baseURL, headers, method, url } = error?.config;

    if (error.response && error.response.status === 401) {
      window.location.replace("/logout");
    }

    appInsights.trackException({
      exception: error,
      properties: {
        fileName: "dotNetApiMethods.js",
        baseURL,
        headers,
        method,
        url,
        response: error?.response?.data,
      },
    });
    return Promise.reject(error);
  },
);

axiosInstance_credit_scoring.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    const { baseURL, headers, method, url } = error?.config;

    if (error.response && error.response.status === 401) {
      window.location.replace("/logout");
    }

    appInsights.trackException({
      exception: error,
      properties: {
        fileName: "dotNetApiMethods.js",
        baseURL,
        headers,
        method,
        url,
        response: error?.response?.data,
      },
    });
    return Promise.reject(error);
  },
);

axiosFormDataInstance.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    const { baseURL, headers, method, url } = error?.config;

    if (error.response && error.response.status === 401) {
      window.location.replace("/logout");
    }

    appInsights.trackException({
      exception: error,
      properties: {
        fileName: "dotNetApiMethods.js",
        baseURL,
        headers,
        method,
        url,
        response: error?.response?.data,
      },
    });
    return Promise.reject(error);
  },
);

export const getDataWithDotNet = async (url) => {
  try {
    const data = await axiosInstance({
      method: "GET",
      url,
    });
    return data.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.replace("/logout");
    } else {
      throw error;
    }
  }
};

export const getData = (url) => {
  return axiosInstance({
    method: "GET",
    url,
  });
};

export const getBankStatementFormats = (url) => {
  return axiosInstance_bankstatement_extraction({
    method: "GET",
    url,
  });
};

export const getCreditScoringData = (url) => {
  return axiosInstance_credit_scoring({
    method: "GET",
    url,
  });
};

export const getBlobData = (url) => {
  return axiosInstance({
    method: "GET",
    url,
    responseType: "blob",
  });
};
// export const getBlobData = (url) => {
//   return axiosInstance({
//     method: "GET",
//     url,
//     responseType: "blob",
//   });
// };

export const postData = (url, reqBody) => {
  return axiosInstance({
    method: "POST",
    url,
    data: reqBody,
  });
};

export const postBankStatementFormats = (url, reqBody) => {
  return axiosInstance_bankstatement_extraction({
    method: "POST",
    url,
    data: reqBody,
  });
};

export const postCreditScoring = (url, reqBody) => {
  return axiosInstance_credit_scoring({
    method: "POST",
    url,
    data: reqBody,
  });
};

export const updateBankStatementFormats = (url, reqBody) => {
  return axiosInstance_bankstatement_extraction({
    method: "PUT",
    url,
    data: reqBody,
  });
};

export const putData = (url, reqBody) => {
  return axiosInstance({
    method: "PUT",
    url,
    data: reqBody,
  });
};

export const postDataWithDotNet = async (url, reqBody) => {
  const data = await axiosInstance({
    method: "POST",
    url,
    data: reqBody,
  });
  return data.data;
};

export const putDataWithDotNet = async (url, reqBody) => {
  const data = await axiosInstance({
    method: "PUT",
    url,
    data: reqBody,
  });
  return data.data;
};

export const deleteDataWithDotNet = async (url, reqBody) => {
  const data = await axiosInstance({
    method: "DELETE",
    url,
    data: reqBody,
  });
  return data.data;
};

export const postFormDataWithDotNet = async (url, reqBody) => {
  const data = await axiosFormDataInstance({
    method: "POST",
    url,
    data: reqBody,
  });
  return data.data;
};

export const putFormDataWithDotNet = async (url, reqBody) => {
  const data = await axiosFormDataInstance({
    method: "PUT",
    url,
    data: reqBody,
  });
  return data.data;
};
