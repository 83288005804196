import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoint from "../api/ajax-endpoints";

type currencyType = {
  currentCurrencyValue: string;
  activeProductName: string;
  aggregator_id: string;
};
const getTransactionsOverview = async ({
  currentCurrencyValue,
  activeProductName,
  aggregator_id,
}: currencyType) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoint.TRANSACTION_OVERVIEW}?currency=${currentCurrencyValue}&product=${activeProductName}&aggregator_id=${aggregator_id}`
  );
  return data.data;
};

export default function useTransactionsOverview({
  currentCurrencyValue,
  activeProductName,
  aggregator_id,
}: currencyType) {
  return useQuery(
    [
      { currentCurrencyValue, activeProductName, aggregator_id },
      "getTransactionsOverview",
    ],
    getTransactionsOverview,
    {
      enabled:
        currentCurrencyValue && activeProductName && aggregator_id
          ? true
          : false,
      retry: 4,
    }
  );
}
