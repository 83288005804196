import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

interface IgetRecentRepayment {
  aggregatorId: number;
  currentCurrencyValue: string;
  activeProductName: string;
}

const getRecentRepayments = async ({
  aggregatorId,
  currentCurrencyValue,
  activeProductName,
}: IgetRecentRepayment) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_RECENT_PAYMENTS}?aggregator_id=${aggregatorId}&currency=${currentCurrencyValue}&product=${activeProductName}`
  );
  return data.data;
};

export default function useRecentRepayments({
  aggregatorId,
  currentCurrencyValue,
  activeProductName,
}: IgetRecentRepayment) {
  return useQuery(
    [
      { aggregatorId, currentCurrencyValue, activeProductName },
      "getRecentRepayments",
    ],
    getRecentRepayments,
    {
      enabled:
        currentCurrencyValue && activeProductName && aggregatorId
          ? true
          : false,

      refetchOnWindowFocus: false,
    }
  );
}
