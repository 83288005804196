import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoint from "../api/ajax-endpoints";

type currencyType = {
  currentCurrencyValue: string;
  aggregatorId: number;
  activeProductName: string;
};
const getLoanCount = async ({
  currentCurrencyValue,
  aggregatorId,
  activeProductName,
}: currencyType) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoint.LOAN_COUNT}?aggregatorId=${aggregatorId}&currency=${currentCurrencyValue}&product=${activeProductName}`
  );
  return data.data;
};

export default function useLoanCount({
  currentCurrencyValue,
  aggregatorId,
  activeProductName,
}: currencyType) {
  return useQuery(
    [{ currentCurrencyValue, aggregatorId, activeProductName }, "getLoanCount"],
    getLoanCount,
    {
      enabled:
        currentCurrencyValue && activeProductName && aggregatorId
          ? true
          : false,
      retry: 4,
    }
  );
}
