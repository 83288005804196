import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

interface IdashboardRepaymenttGraph {
  aggregatorId: number;
  filter: number;
  currentCurrencyValue: string;
  activeProductName: string;
}
const getDashboardRepaymentsGraph = async ({
  aggregatorId,
  filter,
  currentCurrencyValue,
  activeProductName,
}: IdashboardRepaymenttGraph) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.DASHBOARD_REPAYMENTS_GRAPH}?aggregatorId=${aggregatorId}&currency=${currentCurrencyValue}&filter=${filter}&product=${activeProductName}`
  );
  return data;
};

export default function useDashboardRepaymentGraph({
  aggregatorId,
  filter,
  currentCurrencyValue,
  activeProductName,
}: IdashboardRepaymenttGraph) {
  return useQuery(
    [
      { aggregatorId, filter, currentCurrencyValue, activeProductName },
      "getDashboardRepaymentsGraph",
    ],
    getDashboardRepaymentsGraph,
    {
      enabled: currentCurrencyValue && activeProductName ? true : false,

      refetchOnWindowFocus: false,
      cacheTime: 1000,
    }
  );
}
